import { LitElement, html, css } from 'lit';
import { eXistdbRegister } from './existdb-register/existdb-register';

/*
 * Manage a login form for eXist's login endpoint.
 */

export class EplibLogin extends LitElement {
  static get properties() {
    return {
      ...super.properties,
      loginForm: {
        type: HTMLFormElement
      },
      loginpanel: {
        type: HTMLElement
      },
      registerpanel: {
        type: HTMLElement
      }
    };
  }

  connectedCallback() {
    super.connectedCallback();
  }

  async firstUpdated() {
    this.loginForm = this.shadowRoot.querySelector('#loginform');
    this.loginForm.addEventListener('submit', this._doLogin);

    this.loginpanel = this.shadowRoot.querySelector('#loginpanel');
    this.registerpanel = this.shadowRoot.querySelector('#registerpanel');
    this.showLogin();
  }

  disconnectedCallback() {
    this.loginForm.removeEventListener('submit', this._doLogin);
    this._reset();
  }

  async _doLogin(event) {
    event.preventDefault();
    // A lot of work to get the component we're on so we can call its methods.
    var that = this.getRootNode({ composed: true }).querySelector('eplib-login');
    that._hideError();

    var url = new URL(window.location.origin + '/exist/apps/shc/login');

    await fetch(url,
      {
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          user: this.querySelector('#username').value,
          password: this.querySelector('#password').value
        })
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(` (HTTP) ${response.status} - ${response.statusText}`);
        };
        return response.json();
      })
      .then(data => {
        if (data.fail) {
          throw new Error(` (login) ${data.fail}`);
        }
        // Login succeeded
        this.reset();
        that._close();
        window.location.reload(true);
      })
      .catch((error) => {
        that._showError(`${error}`);
      });
  }

  _showError(message) {
    console.error(message);
    this.shadowRoot.querySelector('#loginerror').classList.remove('hidden');
    this.shadowRoot.querySelector('#loginmessage').innerHTML = message;
  }

  _hideError() {
    this.shadowRoot.querySelector('#loginerror').classList.add('hidden');
    this.shadowRoot.querySelector('#loginmessage').innerHTML = "";
  }

  _close(e) {
    this.loginForm.reset();
    this._hideError();
    var dialog = document.getElementById("loginDialog");
    if (dialog) {
      var modal = bootstrap.Modal.getInstance(dialog);
      modal.toggle();
    }
  }

  // Handle form control focus changes.

  _addFormGroupFocus(event) {
    var formGroup = event.target.parentNode;
    formGroup.classList.add("is-focused");
  }

  _removeFormGroupFocus(event) {
    var formGroup = event.target.parentNode;
    formGroup.classList.remove("is-focused");
  }

  // Handle switching back and forth between login and registration.

  showRegistration() {
    this.loginpanel.classList.add('hidden');
    this.registerpanel.classList.remove('hidden');
    var registerComponent = this.shadowRoot.querySelector('existdb-register');
    if (registerComponent) {
      registerComponent._reset();
    }
  }

  showLogin() {
    this.loginForm.reset();
    this.loginpanel.classList.remove('hidden');
    this.registerpanel.classList.add('hidden');
    this._hideError();
  }

  render() {
    return html`
    <link href="https://fonts.googleapis.com/css?family=IM+Fell+English+SC|IM+Fell+English:400,400i|Open+Sans:400,400i&amp;display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
    <link rel="stylesheet" type="text/css" href="../resources/css/style.css"/>

    <div id="loginpanel" class="panel panel-default">
        <div class="panel-heading">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" @click="${this._close}">
                <i class="material-icons">close</i>
            </button>
            <h4>Login</h4>
        </div>
        <div class="panel-body">
            <form id="loginform" method="POST">
                <div class="form-group">
                    <label class="control-label">User ID</label>
                    <input id="username" type="text" name="user" class="form-control" autofocus="autofocus" required="true" autocomplete="username" @focus="${this._addFormGroupFocus}" @blur="${this._removeFormGroupFocus}"/>
                </div>
                <div class="form-group">
                    <label class="control-label">Password</label>
                    <input id="password" type="password" name="password" class="form-control" required="true" autocomplete="current-password" @focus="${this._addFormGroupFocus}" @blur="${this._removeFormGroupFocus}"/>
                </div>
                <div id="loginerror" class="alert alert-error hidden">
                    <span id="loginmessage" class="col-md-12"></span>
                </div>
                <div class="form-group">
                    <div class="col-md-12">
                        <button type="submit" class="btn btn-primary btn-raised form-control">Sign me in</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="btn-group btn-group-justified">
            <div class="col-md-6">
                <a href="#" class="btn btn-default" @click="${this.showRegistration}">Register
                    <div class="material-icons">arrow_forward</div>
                </a>
            </div>
        </div>
    </div>
    <div id="registerpanel" class="panel panel-default hidden">
        <div class="panel-heading">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" @click="${this._close}">
                <i class="material-icons">close</i>
            </button>
            <h4>Register</h4>
        </div>
        <div class="panel-body">
            <existdb-register target="/exist/apps/shc/components/existdb-register/shc-register.xql"></existdb-register>
        </div>
        <div class="btn-group btn-group-justified">
            <div class="col-md-6">
                <a @click="${this.showLogin}" href="#" class="btn btn-default">
                    <div class="material-icons">arrow_back</div> back to login
                </a>
            </div>
        </div>
    </div>
    `;
  }

  static get styles() {
    return css`
    :host {
      display: block;
      box-sizing: border-box;
      width: 100%;
      font-family: "IM Fell English", serif;
      position: relative;
    }

    .form-control, .btn {
      font-family: "IM Fell English", serif;
      width: 100%;
      height:50px;
    }

    .form-group label {
      top: 7px;
    }

    a {
      text-decoration: none;
      color: #0288D1;
    }

    a:hover {
      text-decoration: underline;
    }

    .btn-group-justified {
      display: inline-block;
    }

    .alert.alert-error {
      background-color: white;
      border: 3px solid #ed6363;
      padding: 5px;
    }

    .panel {
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0 1px 1px rgba(0,0,0,.05);
    }
    .panel-body {
      padding: 15px;
    }
    .h4, h4 {
      font-size: 18px;0%;
      margin-left: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .form-group {
      margin-bottom: 15px;
    }
    .form-control {
      display: block;
      line-height: 1.42857143;
      color: #555;
      margin: 0;
      padding-bottom: 0;
      font-size: 14px;
    }

    .material-icons {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
    }

    .btn {
      display: inline-block;
      font-weight: 400;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      touch-action: manipulation;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
    }
    .btn,
    .input-group-btn .btn {
      border: none;
      border-radius: 2px;
      margin: 5px 1px;
      text-transform: uppercase;
      letter-spacing: 0;
      will-change: box-shadow, transform;
      transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
      outline: 0;
      cursor: pointer;
      text-decoration: none;
      background: 0 0;
    }

    .btn:not(.btn-raised),
    .btn:not(.btn-raised).btn-default,
    .input-group-btn .btn:not(.btn-raised),
    .input-group-btn .btn:not(.btn-raised).btn-default {
      color: #4f4f4f;
    }
    .panel-heading {
      padding: 10px 10px;
      border-bottom: 1px solid transparent;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
    .panel.panel-default > .panel-heading,
    .panel > .panel-heading {
      background-color: #4f4f4f;
      color: white;
      opacity: 1.0;
    }
    .btn.btn-raised,
    .btn.btn-raised.btn-primary {
      padding: 15px 0;
      background-color: #4f4f4f;
      color: white;
    }
    .btn.btn-raised:not(.btn-link):hover.btn-primary,
    .btn.btn-raised:not(.btn-link):hover.btn-primary {
      background-color: rgba(153, 153, 153, 0.2);
      color: #4f4f4f;
    }

    .close {
      float: right;
      font-size: 21px;
      font-weight: 700;
      line-height: 1;
      color: white;
      background-color: #4f4f4f;
      text-shadow: 0 1px 0 #fff;
      opacity: 1.0;
      border: 0;
      padding: 0;
    }
    .close:focus, .close:hover {
      color: #bfbfbf;
      text-decoration: none;
      cursor: pointer;
    }

    .hidden {
      display: none;
      visibility: hidden;
    }
    `;
  }

}
customElements.define('eplib-login', EplibLogin);