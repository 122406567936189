import { LitElement, html, css } from 'lit';
import {eplibMixin} from './eplib-mixin';

/**
 * Adapted from the pb-paginate component of tei-publisher-components
 * 
 * Display a pagination control from which the user can select a page to view
 * from a multi-page collection of resources. To determine the number of pages,
 * `eplib-paginate` listens for the `eplib-results-received` event.
 * If the user clicks on one of the page indicators, a `eplib-load` event is emitted,
 * which should cause the connected `eplib-load` element to refresh.
 *
 * @fires eplib-load - Fires when user selects new page to show
 * @fires eplib-results-received - When received, recalculates page ranges to display according to the parameters received
 */
export class EplibPaginate extends eplibMixin(LitElement) {
    static get properties() {
        return {
            ...super.properties,
            /**
             * total number of pages
             */
            total: {
                type: Number,
                reflect: true
            },
            /**
             * Optional extra info about total, such as total number of hits
             * in KWIC results.
             */
            listDescription: {
                type: String,
                attribute: 'list-description'
            },
            /**
             * Optional extra info about what we are paginating through, e.g.
             * hits, documents, annotations, etc.
             */
            totalDescription: {
              type: String,
              attribute: 'total-description'
          },
            /**
             * start page
             */
            start: {
                type: Number,
                reflect: true
            },
            /**
             * amount of entries per page
             */
            perPage: {
                type: Number,
                attribute: 'per-page'
            },
            /**
             * i18n key to use for looking up the message showing number of items in list
             * or fixed label to display
             */
            foundLabel: {
                type: String,
                attribute: 'found-label'
            },
            /**
             * the current page
             */
            page: {
                type: Number
            },
            /**
             * the amount of pages
             */
            pageCount: {
                type: Number,
                attribute: 'page-count'
            },
            /**
             * todo
             */
            range: {
                type: Number
            },
            /**
             * todo:
             */
            pages: {
                type: Array
            },
            /**
             * on first page so disable previous page navigation
             */
            _disablePrevious: {
                type: String
            },
            /**
             * on last page so disable next page navigation
             */
            _disableNext: {
                type: String
            }
        };
    }

    constructor() {
        super();
        this.total = 0;
        this.start = 1;
        this.perPage = 10;
        this.page = 1;
        this.pageCount = 10;
        this.range = 5;
        this.pages = [];
        this.foundLabel = 'browse.items';
        this._disablePrevious = 'disabled';
        this._disableNext = '';
    }

    connectedCallback() {
        super.connectedCallback();

        this.subscribeTo('eplib-results-received', this._refresh.bind(this));
    }

    render() {
        return html`
            <link href="https://fonts.googleapis.com/css?family=IM+Fell+English+SC|IM+Fell+English:400,400i|Open+Sans:400,400i&amp;display=swap" rel="stylesheet">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
            <link rel="stylesheet" type="text/css" href="../node_modules/bootstrap/dist/css/bootstrap.min.css"/>
            <link rel="stylesheet" type="text/css" href="../resources/css/style.css"/>

            <div class="row">
                <div class="col-md-6">
                    <h5 class="float-start found" style="margin-left: 22px">
                        ${this.start}-${Math.min(this.start + this.perPage - 1, this.total)} of ${ this.total.toLocaleString() } ${this.listDescription}
                        <br/>
                        ${this.totalDescription}
                    </h5>
                </div>
                <div class="col-md-6">
                    <ul class="pagination float-end">
                        <li class="page-item ${this._disablePrevious}" @click="${this._handleFirst}"><a class="material-icons page-link">first_page</a></li>
                        <li class="page-item ${this._disablePrevious}" @click="${this._handlePrevious}"><a class="material-icons page-link">navigate_before</a></li>
                        ${this.pages.map((item, index) => html`<li class="${item.class} page-item" @click="${() => this._handleClick(index)}"><a class="page-link">${item.label}</a></li>`)}
                        <li class="page-item ${this._disableNext}" @click="${this._handleNext}"><a class="material-icons page-link">navigate_next</a></li>
                        <li class="page-item ${this._disableNext}" @click="${this._handleLast}"><a class="material-icons page-link">last_page</a></li>
                    </ul>
                </div>
            </div>
        `;
    }

    static get styles() {
        return css`
            :host([total="0"]) {
                display: none;
            }
            :host {
                margin: 0.75rem 0.25rem 0.75rem 0.25rem;
            }
            h5 {
              font-family: "IM Fell English", serif !important;
              line-height: 1.2 !important;
              margin-left: 1rem;
            }
        `;
    }

    _update(start, total) {
        if (!total || !start) {
            return;
        }
        this.pageCount = Math.ceil(total / this.perPage);
        this.page = Math.ceil(start / this.perPage);
        let lowerBound = Math.max((this.page - Math.ceil(this.range / 2) + 1), 1);
        let upperBound = Math.min((lowerBound + this.range - 1), this.pageCount);
        lowerBound = Math.max((upperBound - this.range + 1), 1);
        console.log("<eplib-paginate> start: %d, total: %d, perPage: %d, pageCount: %s, page: %d, lower: %d, upper: %d",
            start, total, this.perPage, this.pageCount, this.page, lowerBound, upperBound);
        const pages = [];
        for (let i = lowerBound; i <= upperBound; i++) {
            pages.push({
                label: i,
                "class": i === this.page ? "active" : ""
            });
        }
        this.pages = pages;
    }

    _refresh(ev) {
        this.start = Number(ev.detail.start);
        this.total = ev.detail.count;
        this._update(this.start, this.total);
    }

    _handlePageNavigation(page) {
        this.start = (page - 1) * this.perPage + 1;
        this._disablePrevious = page === 1 ? 'disabled' : '';
        this._disableNext = page === this.pageCount ? 'disabled' : '';
        this.emitTo('eplib-load', {
            "params": {
                "start": this.start,
                "per-page": this.perPage,
                "page": page
            }
        });
    }

    _handleClick(index) {
        this._handlePageNavigation(this.pages[index].label);
    }

    _handleFirst() {
        this._handlePageNavigation(1);
    }

    _handleLast() {
        this._handlePageNavigation(this.pageCount);
    }

    _handlePrevious() {
        if (this.page <= 1) {
            return;
        }
        var page = this.page - 1;
        this._handlePageNavigation(page);
    }

    _handleNext() {
        if (this.page >= this.pageCount) {
            return;
        }
        var page = this.page + 1;
        this._handlePageNavigation(page);
    }
}
customElements.define('eplib-paginate', EplibPaginate);
