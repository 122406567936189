import { LitElement, html, css } from 'lit';

/**
 * Display a page slider with a tooltip containing EEBO page identifiers
 */
export class EplibPageSlider extends LitElement {
  static get properties() {
    return {
      ...super.properties,
      /**
       * the current document
       */
      doc: {
        type: String
      },
      /**
       * the current page
       */
      page: {
        type: Number
      },
      /**
       * Array of value / page ID mappings:
       */
      pages: {
        type: Array
      },
      /**
       * current drag state
       */
      isDragging:
      {
        type: Boolean
      },
      /**
       * Element to which tooltip showing page ID is attached
       */
      toolTipEl:
      {
        type: HTMLElement
      },
      /**
       * the slider input element
       */
      slider:
      {
        type: HTMLInputElement
      },
      /**
       * the total number of pages and thus one more than the maximum slider index
       */
      max:
      {
        type: Number
      }
    };
  }

  constructor() {
    super();
    this.addEventListener('mouseup', e => this.onMouseUp(e));
    this.addEventListener('mousedown', e => this.onMouseDown(e));
  }

  connectedCallback() {
    super.connectedCallback();
  }

  async firstUpdated() {
    this.pages = [];
    this.max = 0;
    this.isDragging = false;
    this.slider = this.shadowRoot.querySelector('#page_slider');
    this.toolTipEl = this.shadowRoot.querySelector('#page_slider_tooltip');
    this.toolTipEl.style.visibility = 'hidden';

    var pathname = window.location.pathname;
    this.doc = pathname.substring(pathname.lastIndexOf('/') + 1);

    // The Ajax call gets the list of page IDs for this document.
    var protocol = window.location.protocol;
    var host = window.location.hostname;
    var port = window.location.port;
    if (protocol === 'http:' && port.length > 0 && port != '80'
      || protocol === 'https:' && port.length > 0 && port != '443') {
      host += ':' + port;
    }
    var appRoot = protocol + '//' + host + '/exist/apps/shc';

    await fetch(appRoot + "/modules/ajax-pages.xql?doc=" + this.doc + "&")
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        };
        return response.json();
      })
      .then(data => {
        if (data.error) {
          console.log(`Page slider data error: ${data.error}`);
          return;
        }
        this.pages = data['pages'].slice();
        this.max = this.pages.length;
        this.slider.setAttribute('value', 1);
        this.slider.setAttribute('min', 1);
        this.slider.setAttribute('max', this.max);
      })
      .catch((error) => {
        console.error(`Error retrieving remote content for page slider: ${error}`);
      });
  }

  moveTip(e) {
    if (this.isDragging) {
      var thumbHalfWidth = 4;
      var pixPos = (((this.slider.value - 1) / (this.max - 1))
        * ((this.slider.clientWidth - thumbHalfWidth) - thumbHalfWidth))
        + thumbHalfWidth;
      pixPos = pixPos - this.toolTipEl.offsetWidth / 2; // align with tooltip center
      this.toolTipEl.style.marginLeft = `${pixPos}px`;
      var pbid = this.pages[Math.round(this.slider.value) - 1].pbid;
      this.toolTipEl.innerHTML = pbid;
    }
  }

  onMouseDown(e) {
    this.isDragging = true;
    this.addEventListener('mousemove', this.moveTip, false);
    this.toolTipEl.style.visibility = 'visible';
  };

  onMouseUp(e) {
    this.isDragging = false;
    this.toolTipEl.style.visibility = 'hidden';
    this.removeEventListener('mousemove', this.moveTip);
    var pbid = this.pages[Math.round(this.slider.value) - 1].pbid;
    var newPage = pbid.substring(pbid.indexOf("-", pbid) + 1);
    if (this.page && this.page === newPage) {
      return; // didn't move
    }
    this.page = newPage;
    var url = "doc=" + this.doc + "&page=" + this.page;

    console.log('now opening page ' + url);

    const event = new CustomEvent("eplib-load-page", {
      detail: {
        doc: this.doc,
        page: this.page
      }
    });

    this.dispatchEvent(event);
  };

  render() {
    return html`
      <div>
          <input autocomplete="off" type="range" id="page_slider" step="any"/>
      </div>
      <div class="tooltip">
          <span class="tooltiptext" id="page_slider_tooltip">Drag to select page.</span>
      </div>
      `;
  }

  static get styles() {
    return css`
      // Make the tooltip appear on top of the slider.
      // :host is sometimes ignored so also put this first one in style.less
      :host {
        display: grid;
        justify-items: stretch;
        align-items: start;
      }

      :host > div {
        grid-column-start: 1;
        grid-row-start: 1;
      }

      #page_slider {
        width: 100%;
        background: url('../resources/images/press_paper.png') top left repeat;
        z-index: 10;
      }

      input[type=range] {
        -webkit-appearance: none;
        width: 100%;
        margin: 0 0 0 0;
      }
      input[type=range]:focus {
        outline: none;
      }
      input[type=range]::-webkit-slider-runnable-track {
        width: 100%;
        height: 6px;
        cursor: pointer;
        box-shadow: 1px 1px 1px #00430f, 0px 0px 1px #005d15;
        background: #4f4f4f;
        border-radius: 1.3px;
        border: 0.75px solid white; //rgba(1, 1, 1, 0.76);
      }
      input[type=range]::-webkit-slider-thumb {
        box-shadow: 2px 2px 4px #000031, 0px 0px 3px #00004b;
        border: 0.75px solid white; //#00001e;
        height: 15px;
        width: 8px;
        border-radius: 3px;
        background: #4f4f4f;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -5.0px;
      }
      input[type=range]:focus::-webkit-slider-runnable-track {
        background: #4f4f4f;
      }
      input[type=range]::-moz-range-track {
        width: 100%;
        height: 6px;
        cursor: pointer;
        box-shadow: 1px 1px 1px #00430f, 0px 0px 1px #005d15;
        background: #4f4f4f;
        border-radius: 1.3px;
        border: 0.75px solid white; //rgba(1, 1, 1, 0.76);
      }
      input[type=range]::-moz-range-thumb {
        box-shadow: 2px 2px 4px #000031, 0px 0px 3px #00004b;
        border: 0.75px solid white; //#00001e;
        height: 15px;
        width: 8px;
        border-radius: 3px;
        background: #4f4f4f;
        cursor: pointer;
      }
      input[type=range]::-ms-track {
        width: 100%;
        height: 6px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        color: transparent;
      }
      input[type=range]::-ms-fill-lower {
        background: #4f4f4f;
        border: 0.75px solid white; //rgba(1, 1, 1, 0.76);
        border-radius: 2.6px;
        box-shadow: 1px 1px 1px #00430f, 0px 0px 1px #005d15;
      }
      input[type=range]::-ms-fill-upper {
        background: #4f4f4f;
        border: 0.75px solid white; //rgba(1, 1, 1, 0.76);
        border-radius: 2.6px;
        box-shadow: 1px 1px 1px #00430f, 0px 0px 1px #005d15;
      }
      input[type=range]::-ms-thumb {
        box-shadow: 2px 2px 4px #000031, 0px 0px 3px #00004b;
        border: 0.75px solid white; //#00001e;
        height: 15px;
        width: 8px;
        border-radius: 3px;
        background: #4f4f4f;
        cursor: pointer;
      }
      input[type=range]:focus::-ms-fill-lower {
        background: #4f4f4f;
      }
      input[type=range]:focus::-ms-fill-upper {
        background: #4f4f4f;
      }

      // Tooltip styles in close imitation of:
      // https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_tooltip
      // However, hover state doesn't seem to work here so visibility is set
      // programatically.

      .tooltip {
        position: relative;
        display: inline-block;
      }

      .tooltip .tooltiptext {
        background-color: #4f4f4f;
        color: #fff;
        font-size: 12px;
        font-family: Georgia, "Times New Roman", serif;
        text-align: center;
        border-radius: 6px;
        padding: 5px 5px;
        position: absolute;
      }

      .tooltiptext {
        bottom: 17px;
      }

      .tooltip .tooltiptext::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
      }
    `;
  }

}
customElements.define('eplib-page-slider', EplibPageSlider);
