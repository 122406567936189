import { LitElement, html, css } from 'lit';
import { eplibMixin, clearPageEvents } from './eplib-mixin.js';

/**
 * Make sure there's only one instance of eplib-page active at any time.
 */
let _instance;

/**
 * Adapted from the pb-page component of tei-publisher-components
 * 
 * Configuration element which should wrap around other `eplib-` elements.
 * Among other things, this element determines the endpoint.
 * 
 * @slot - default unnamed slot for content
 * @fires pb-page-ready - fired when the endpoint has been determined
 */
class EplibPage extends eplibMixin(LitElement) {

    static get properties() {
        return {
            ...super.properties,
            /**
             * TEI Publisher internal: set to the root URL of the current app
             */
            appRoot: {
                type: String,
                attribute: 'app-root'
            },
            /**
             * TEI Publisher internal: set to the current page template.
             */
            template: {
                type: String
            },
            /**
             * The base URL of the TEI Publisher instance. All nested elements will
             * talk to this instance. By default it is set to the URL the
             * page was loaded from.
             * 
             * The endpoint can be overwritten by providing an HTTP request parameter
             * `_target` with an URL.
             */
            endpoint: {
                type: String,
                reflect: true
            },
            apiVersion: {
                type: String,
                attribute: 'api-version',
                reflect: true
            },
            /**
             * Will be set while the component is loading and unset when
             * it is fully loaded. Use to avoid flash of unstyled content
             * via CSS: set `unresolved` on `pb-page` in the HTML and
             * add a CSS rule like:
             * 
             * ```css
             * pb-page[unresolved] {
             *     display: none;
             * }
             * ```
             */
            unresolved: {
                type: Boolean,
                reflect: true
            }
        };
    }

    constructor() {
        super();
        this.unresolved = true;
        this.endpoint = ".";
        this.apiVersion = 1.0;

        if (_instance) {
            this.disabled = true;
        } else {
            _instance = this;

            // clear global page events which might have been set by other pb-page instances.
            // important while running the test suite.
            clearPageEvents();
        }
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        if (_instance === this) {
            // clear to allow future instances
            _instance = null;
        }
    }

    async connectedCallback() {
        super.connectedCallback();
        
        if (this.disabled) {
            return;
        }

        const target = this.getParameter('_target');
        if (target) {
            this.endpoint = target;
        }

        this.signalReady('pb-page-ready', {
            endpoint: this.endpoint,
            template: this.template,
            apiVersion: this.apiVersion
        });

    }

    firstUpdated() {
        super.firstUpdated();

        if (this.disabled) {
            return;
        }

        this.unresolved = false;

        console.log('<eplib-page> endpoint: %s; trigger window resize', this.endpoint);
    }

    render() {
        return html`<slot></slot>`;
    }

    static get styles() {
        return css`
        :host {
            display: block;
        }
        `;
    }

    resolveURL(relPath) {
        return new URL(relPath, window.location.href).href;
    }
}

customElements.define('eplib-page', EplibPage);